import { getSignatures } from "../commonDynmicForms/signature";
import { constants } from "../constants";
import { FillableBy } from "./form_enums";
import { table_fields} from "../commonDynmicForms/table_fields"

  export default {
    fillable_by:FillableBy.Both,
    title:
      "Cognitive i-STAT User Training And Competency Demonstration",
    sections: [
      // {...employeeinfo},
            {
              title:"",
              type: "fields",
              fields: [
                {
                  fillable_by : FillableBy.Employee,
                  label: "Date of Abbott i-STAT Online Training Course Completion",
                  type: "a-date-picker",
                  span: 12,
                  value: "",
                  rules: {
                    required: true,
                    message: "This field is required.",
                  },
                },
              ]
            },
             {
                 title: "",
                 type: "table",
                 heads: constants.tableHeads,
                 rows: [
                  "Identifies all components of the i-STAT System",
                 "Instruction of proper sample collection provided (both arterial and venous cut sampling)",
                  "Instruction of proper sample handling provided",
                 "User fills and closes the cartridge correctly",
                  "Instruction of disposal of contaminated lab provided",
                  "User explains all prompts and displays",
                  "Demonstrates ability to enable CIP mode",
                  "Demonstrates ability to access previous results",
                  "Describes QC procedures for cartridges and analyzer",
                 "Describes both electronic and paper results documenting",
                  "Explains proper cartridge and QC agent storage and explanation (both refrigerated and room temperature)",
                 "User explains troubleshooting procedures",
                  "User describes alternative testing procedure",
                  "Opportunity for questions and answers provided",
                  "User is familiar with procedure manual and has access to it",
                 ].map((item) => ([{ text: item, type: 'text' }, ...table_fields])),
             },
      {
        title: "Signatures",
        type: "signature",
        fields: getSignatures(FillableBy.Both)
      },
    ]
  }